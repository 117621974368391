<template>
  <div class="cloudAcceleration29">
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/top.png" />
    <div class="scroll_tips">
      <ul class="scroll_box">
        <li class="scroll_item">176****6142抢到了</li>
        <li class="scroll_item">131****7394抢到了</li>
        <li class="scroll_item">155****4549抢到了</li>
        <li class="scroll_item">130****5436抢到了</li>
        <li class="scroll_item">156****0054抢到了</li>
        <li class="scroll_item">171****5245抢到了</li>
        <li class="scroll_item">175****6540抢到了</li>
        <li class="scroll_item">185****0974抢到了</li>
      </ul>
    </div>
    <div class="form">
      <img class="one" v-show="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入您的手机号码" />
      <template>
        <img class="two" v-show="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif" />
        <img class="three" v-show="num == 3" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif" />
        <van-field v-model="form.code" type="number" center maxlength="6" autocomplete="one-time-code"
          placeholder="请输入短信验证码" :class="['input-code', { 'two-cell': num == 2 }]">
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/submit.png"
        :class="['submit']" @click="onSubmit" />

      <img class="four" v-show="num == 4" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif" />
      <div class="tag">专享25元彩铃云盘流量组合包（上海）</div>
    </div>
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/card1.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/card2.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/info1.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/info2.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/info3.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/info2.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/cloudAcceleration29/info4.png" />
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShangHai/videoRingtone25/footer.png" />
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index.js";
import { liuliangguo } from "@/utils/common";

export default {
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "314205168716",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      visibility: false,
    };
  },
  created() {
    const { from = "", phone = "", reasonCode = ""  } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      phone,
      characteristic: from + reasonCode,
      marketUrl: encodeURIComponent(href)
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      liuliangguo("103813", phone, "专享29元安全管家云加速组合包");
    };
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getAuthCode() {
      if (this.yzm_loading) return;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.yzm_loading = true;
        try {
          getVerificationCode(
            JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
          )
            .then((res) => {
              this.yzm_loading = false;
              if (res.code != 0) {
                this.$dialog.alert({ message: res.message || '验证码获取失败' });

                liuliangguo(
                  "103810",
                  this.form.phone,
                  "专享29元安全管家云加速组合包"
                );
              } else {
                // 设置倒计时秒
                this.sendAuthCode = false;
                this.auth_time = 120;
                let auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              this.yzm_loading = false;
            });
        } finally {
          this.yzm_loading = false;
        }
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const that = this;
      tradeOrder(JSON.stringify(that.form)).then((res) => {
        that.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || '提交失败',
          });
          liuliangguo("103812", this.form.phone, "专享29元安全管家云加速组合包 ");

        } else {
          that.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          liuliangguo("103811", this.form.phone, "专享29元安全管家云加速组合包");

        }
      });
    },

    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" }).then(() => {
          if (this.sendAuthCode) {
            this.getAuthCode();
          }
        });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else {
        // this.visibility = true;
        this.handleSubmit();
      }
    },
    showDialog(title) {
      const data = {
        活动说明: `<b style="color: #000">一、业务介绍：</b>
本产品包含联通云盘500G畅享会员2022年版6元/月（立即生效）+视频彩铃铂金会员15元包月+标准-流量包8GB-4元-立即生效互联网月卡。


<b style="color: #000">二、产品介绍</b>
1、资费及计费
产品名称：<span style="color: red;">专享25元彩铃云盘流量组合包（上海）</span>，，资费：<span style="color: red;">25元/月</span>，产品开通<span style="color: red;">立即生效</span>，次月<span style="getVerificationCode700; color: red;">自动续订</span>。

<b style="color: #000">三、产品使用说明：</b>
<b style="color: #000">（一）彩铃任意设：</b>登录联通视频彩铃APP、联通视频彩铃小程序、中国联通APP视频彩铃专区等，选择喜欢的视频内容设置为视频彩铃或主叫彩铃。
1、视频彩铃：设置成功后，亲友拨打您的电话时可以看到对应的视频彩铃内容。
2、主叫彩铃：设置成功后，主叫用户拨打电话时可以看到自己设置的主叫彩铃内容。
3、视频彩铃分时播放设置、分组播放设置。
<b style="color: #000">（二）视频彩铃DIY：</b>登录联通视频彩铃APP、联通视频彩铃小程序、中国联通APP视频彩铃专区等，进入彩铃DIY板块，选择本地上传、模板制作、AI生成等的方式，制作专属于自己的视频彩铃。
<b style="color: #000">（三）热门权益月月领：</b>登录联通视频彩铃APP、联通视频彩铃小程序、中国联通APP视频彩铃专区等，通过“我的”-“会员中心”进入权益领取专区，多款热门音视频互联网月卡每月N选1。
<b style="color: #000">（四）云盘海量空间：</b>产品内含联通云盘 500G 畅享会员，用户可在IOS/安卓应用市场下载“智家云盘”APP，登录即可享海量存储空间、上传下载不限速、照片视频快捷备份、群组资料一键共享。
<b style="color: #000">（五）大流量：</b>流量使用规则（产品内含8G通用流量，流量使用优先级低于主套餐套内流量，主卡订购副卡可共享，次月未退订未使用流量可结转，用户可在手厅查询流量使用情况；）。
<b style="color: yellow">注：权益具体以领取页面为准；</b>

<b style="color: #000">四、业务退订流程：</b>
退订后该产品权益持续到当月底，次月失效。
1. 中国联通APP：中国联通APP首页-我的-已订业务，选择要退订的产品，点击“退订”，输入验证码进行退订。
2. 人工退订：用户前往中国联通营业厅或拨打10010热线即可办理产品退订。
3. 如有其他问题，请拨打客服4001189599。
`,
        个人信息保护授权协议: `1.引言
欢迎您使用我们的互联网包月产品。我们非常重视您的隐私和数据安全，承诺采取一切合理措施来保护您的个人信息。本隐私协议详细说明了我们如何收集、使用、共享和保护您的个人信息。
2.收集的信息
当您订购互联网包月产品时，我们可能会收集以下信息:
·个人信息:包括但不限于您的姓名、地址、电子邮件地址、电话号码等。
·支付信息:用于处理您的订购支付的信用卡信息、银行账户信息或其他支付信息。
·使用数据: 我们可能会收集关于您使用互联网包月产品的数据，如您的访问记录、浏览历史、下载量等。
3.信息用途
我们收集您的信息主要用于以下目的:
·履行合同:处理您的订购请求，提供互联网包月服务，并与您沟通相关信息。
·客户支持:解决您的问题、回答您的查询，并提供技术支持。
·改进产品:分析使用数据以改进互联网包月产品的质量和性能。
4.信息共享
我们承诺不会出售、出租或交易您的个人信息给第三方，除非:
·您明确同意共享。
·法律、法规或法院命令要求共享。
·为了保护我们的权利、财产或安全需要共享。
5.数据安全
我们采取了合理的安全措施来保护您的个人信息，以防止未经授权的访问、泄露、滥用或损坏。我们定期审查和更新我们的安全措施以适应不断变化的威胁。
6.数据存储和保留
我们将您的个人信息存储在安全的服务器上，并根据适用法律和合同要求的时间保留这些信息。一旦不再需要，我们将安全地销毁或匿名化这些信息。
7.隐私权利
根据适用法律，您有权:
·访问、更正或删除您的个人信息。
8.变更通知
我们可能会定期更新本隐私协议，以反映我们的数据处理实践和法律要求的变化。请定期查看以获取最新信息。
9.同意
通过使用我们的互联网包月产品，您表示已阅读、理解并同意本隐私协议中的所有条款。如果您不同意这些条款，请不要使用我们的服务。感谢您信任我们保护您的个人信息。我们将继续努力确保您的隐私和数据安全。
`,
      };
      this.$dialog.alert({
        messageAlign: "left",
        title: `《${title}》`,
        message: data[title],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cloudAcceleration29 {
  background-color: #e33b2b;

  .scroll_tips {
    width: 100%;
    margin: -40px auto 0;
    overflow: hidden;

    .scroll_box {
      height: 61px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 61px;
        font-size: 30px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }

  .img {
    width: 100%;
    display: block;
  }

  .form {
    position: relative;
    padding: 0;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(108px, -80px);
    }

    .two {
      width: 152px;
      right: 40px;
      top: 50px;
      transform: translate(-2px, 0px);
    }

    .three {
      width: 430px;
      transform: translate(108px, -20px);
    }

    .four {
      width: 246px;
      transform: translate(280px, -74px);
    }

    ::v-deep .van-cell {
      width: 636px;
      height: 110px;
      background-color: #ffffff;
      border-radius: 60px;
      margin: 70px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 56px;

      &.input-code {
        margin-top: 40px;

        .code {
          width: 204px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: #ff7900;
          border-left: 2px solid #ff7900;
          font-size: 28px;
          font-weight: normal;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;
        font-weight: 500;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: normal;
          color: #969696;
        }
      }
    }

    .submit {
      width: 630px;
      // height: 180px;
      display: block;
      margin: 50px auto 0;
      animation: identifier 0.8s infinite linear;

      @keyframes identifier {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(0.9);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    .tag {
      font-size: 24px;
      font-weight: normal;
      text-align: center;
      line-height: 36px;
      color: #ffffff;
      font-weight: 500;
      padding-top: 60px;
    }
  }

  ::v-deep .dialog-confirm {
    padding-top: 96px;

    .footer {
      padding-top: 700px;
      align-items: center;
      justify-content: center;

      .btn-close {
        width: 100px;
        height: auto;
        padding: 0;
        margin-right: 110px;
      }

      .btn-submit {
        width: 200px;
        animation: identifier 1s infinite;

        @keyframes identifier {
          0% {
            transform: scale(1);
          }

          50% {
            transform: scale(0.9);
          }

          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
}
</style>
